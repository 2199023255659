<template>
  <v-card class='elevation-0'>
    <v-card-title>
      <div class='headline font-weight-medium'>
        Appointment Date:
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-menu
        ref='dtMenu'
        :close-on-content-click='false'
        nudge-right='40'
        offset-y
        max-width='290px'
        min-width='290px'
        v-model='dtMenu'
      >
        <template v-slot:activator='{ on }'>
          <v-text-field
            v-model='selectedDate'
            label='Select a date:'
            v-on='on'
            readonly
          >
            <template slot='append'>
              <v-icon class='filterIcon grey--text text--darken-1'
                >fa fa-calendar</v-icon
              >
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model='selectedDate'
          no-title
          @input='dtMenu = false'
          :min='minDate'
          :max='maxDate'
        ></v-date-picker>
      </v-menu>
      <v-autocomplete
        label='Available time slots:'
        :items='items'
        item-text='Name'
        item-value='Id'
        :loading='fetching'
        v-model='selectedSlot'
      >
      </v-autocomplete>
    </v-card-text>
  </v-card>
</template>

<script>
import WebH from '../../api/web';
export default {
  created() {
    let min = new Date();
    min.setDate(min.getDate() - 1);
    this.minDate = min.toISOString();
    let max = new Date();
    max.setDate(max.getDate() + 7);
    this.maxDate = max.toISOString();
  },
  data() {
    return {
      dtMenu: false,
      allowedDates: [],
      minDate: null,
      maxDate: null,
      items: [],
      fetching: false
    };
  },
  computed: {
    selectedDate: {
      get() {
        return this.$store.getters['ApptStore/regDate'];
      },
      set(value) {
        this.$store.commit('ApptStore/setRegDate', value);
        this.getSlots(value);
      }
    },
    selectedSlot: {
      get() {
        return this.$store.getters['ApptStore/regSlot'];
      },
      set(value) {
        this.$store.commit('ApptStore/setRegSlot', value);
      }
    }
  },
  methods: {
    getItem(id) {
      switch (id) {
        case 1:
          return { Id: 1, Name: '9:00 AM - 9:15 AM' };
        case 2:
          return { Id: 6, Name: '10:00 AM - 10:15 AM' };
        case 3:
          return { Id: 11, Name: '11:00 AM - 11:15 AM' };
        case 4:
          return { Id: 16, Name: '12:00 PM - 12:15 PM' };
        case 5:
          return { Id: 21, Name: '1:00 PM - 1:15 PM' };
        case 6:
          return { Id: 31, Name: '3:00 PM - 3:15 PM' };
        case 7:
          return { Id: 36, Name: '4:00 PM - 4:15 PM' };
        case 8:
          return { Id: 41, Name: '5:00 PM - 5:15 PM' };
      }
    },
    async getSlots(date) {
      this.fetching = true;
      this.items = [];
      const res = await WebH.Get1(
        '/apptmnts/slots?ad=' + date,
        'appointment slots'
      );
      if (res.Success) {
        const slots = res.Data;
        for (let i = 1; i <= 8; i++) {
          const item = this.getItem(i);
          if (slots.includes(item.Id)) continue;
          this.items.push(item);
          // const slot = slots.find(x => x.Slot === i);
          // if (slot) {
          //   if (slot.Count < 5) this.items.push(this.getItem(i));
          // } else this.items.push(this.getItem(i));
        }
        // if (res.Data.length < 1) {
        //   const slots = res.Data;
        //   for (let i = 1; i <= 7; i++) {
        //     const index = this.items.push(this.getItem(i));
        //   }
        // } else {
        //   for (let i = 0; i < res.Data.length; i++) {
        //     if (res.Data[i].Count < 5) {
        //       this.items.push(this.getItem(res.Data[i].Slot));
        //     }
        //   }
        // }
      } else {
        await this.$notifyDlg.show(res.Data, 'Error', 'error');
      }
      this.selectedSlot = -1;
      this.fetching = false;
    }
  }
};
</script>
