<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm12>
        <div
          class='display-1 green--text text--darken-4 font-weight-medium center-text'
        >
          Need an appointment?
        </div>
        <br />
        <div class='title green--text text--darken-4 center-text'>
          Fill the details below
        </div>
        <br />
        <br />
      </v-flex>
      <v-flex sm12>
        <SmPatient></SmPatient>
      </v-flex>
      <v-flex sm12>
        <SmDate></SmDate>
      </v-flex>
      <v-flex sm12>
        <v-btn color='primary' @click='bookAppt'>
          <v-icon class='iconText'>fa fa-calendar-check</v-icon>
          Book Appointment
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import SmPatient from '../../components/patients/SmPatient.vue';
import SmDate from '../../components/appointment/SmDate';
import WebH from '../../api/web';
export default {
  components: {
    SmPatient,
    SmDate
  },
  computed: {
    patient: {
      get() {
        return this.$store.getters['ApptStore/patient'];
      }
    },
    isReg: {
      get() {
        return this.$store.getters['ApptStore/isReg'];
      }
    },
    regDate: {
      get() {
        return this.$store.getters['ApptStore/regDate'];
      }
    },
    regSlot: {
      get() {
        return this.$store.getters['ApptStore/regSlot'];
      }
    }
  },
  data() {
    return {
      patientId: '',
      patientVerified: false
    };
  },
  methods: {
    async notify(message) {
      await this.$notifyDlg.show(message, 'Error', 'error');
    },
    async checkForPatientDetails() {
      if (this.isReg === 'yes') {
        let search = false;
        if (this.patient.Pid !== '') {
          search = true;
        } else if (this.patient.Name !== '') {
          search = true;
        }
        if (!search) {
          await this.notify('Please enter Patient Id or Registered Name');
          return false;
        }
        if (this.patient.Mobile === '') {
          await this.notify('Please enter Registered Mobile');
          return;
        }
      } else {
        if (this.patient.Mobile === '' && this.patient.Name === '') {
          await this.notify(
            'Please enter Name and Mobile while booking appointment'
          );
          return false;
        }
      }
      return true;
    },
    async checkDateAndSlot() {
      if (this.regDate === null) {
        await this.notify('Please select a date');
        return false;
      }
      if (this.regSlot < 1 || this.regSlot > 7) {
        await this.notify('Please select a valid slot');
        return false;
      }
      return true;
    },
    async bookAppt() {
      if (!(await this.checkForPatientDetails())) return;
      if (!(await this.checkDateAndSlot())) return;
      if (this.isReg === 'no') {
        if (!this.patientVerified) {
          await this.createPatient({
            Name: this.patient.Name,
            Mobile: this.patient.Mobile
          });
        }
      } else {
        if (!this.patientVerified) {
          await this.searchPatient(this.patient);
        }
      }
      if (this.patientVerified) {
        await this.bookAppointment({
          PatientId: this.patientId,
          AppointmentSlot: this.regSlot,
          AppointmentDate: this.regDate
        });
      }
    },
    async searchPatient(data) {
      const loader = this.$loading.show();
      const res = await WebH.Get1(
        '/patients/sappt?pid=' +
          data.Pid +
          '&na=' +
          data.Name +
          '&mob=' +
          data.Mobile,
        'search'
      );
      loader.hide();
      if (res.Success) {
        this.patientVerified = true;
        this.patientId = res.Data;
      } else {
        await this.notify(res.Data);
      }
    },
    async createPatient(data) {
      const loader = this.$loading.show();
      const res = await WebH.Post('/patients/appt', data);
      loader.hide();
      if (res.Success) {
        this.patientId = res.Data;
        this.patientVerified = true;
      } else {
        await this.notify(res.Data);
      }
    },
    async bookAppointment(data) {
      const loader = this.$loading.show();
      const res = await WebH.Post('/apptmnts', data);
      loader.hide();
      if (res.Success) {
        await this.$notifyDlg.show(
          'Successfully booked appointment on ' +
            data.AppointmentDate +
            '\r\n Soon you will get the confirmation sms to your mobile'
        );
        this.patientId = '';
        this.patientVerified = false;
        this.$store.commit('ApptStore/reset');
      } else {
        await this.notify(res.Data);
      }
    }
  }
};
</script>
