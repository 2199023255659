<template>
  <v-card class='elevation-0'>
    <v-card-title>
      <div class='headline font-weight-medium'>
        Patient Details:
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-radio-group v-model='isReg' label='Are you registered patient?' row>
        <v-radio label='Yes' value='yes' color='primary'></v-radio>
        <v-radio label='No' value='no' color='primary'></v-radio>
      </v-radio-group>
      <v-text-field
        label='Patient Id:'
        v-model='pid'
        :disabled='isReg === "no"'
      ></v-text-field>
      <div v-show='isReg === "yes"'>
        or
      </div>
      <br />
      <v-text-field
        v-model='name'
        :label='isReg === "yes" ? "Registered Name:" : "Name:"'
      ></v-text-field>
      <v-text-field
        v-model='mobile'
        :label='isReg === "yes" ? "Registered Mobile:" : "Mobile:"'
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    pid: {
      get() {
        return this.$store.getters['ApptStore/patient'].Pid;
      },
      set(value) {
        this.$store.commit('ApptStore/setPatientId', value);
      }
    },
    name: {
      get() {
        return this.$store.getters['ApptStore/patient'].Name;
      },
      set(value) {
        this.$store.commit('ApptStore/setPatientName', value);
      }
    },
    mobile: {
      get() {
        return this.$store.getters['ApptStore/patient'].Mobile;
      },
      set(value) {
        this.$store.commit('ApptStore/setPatientMobile', value);
      }
    },
    isReg: {
      get() {
        return this.$store.getters['ApptStore/isReg'];
      },
      set(value) {
        this.$store.commit('ApptStore/updateIsReg', value);
      }
    }
  }
};
</script>
